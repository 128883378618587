import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-12a.png'
import image2 from 'images/project-12b.png'
import image3 from 'images/project-12c.png'
import image4 from 'images/project-12d.png'
import image5 from 'images/project-12e.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Financial future looking bright"
      meta={[
        {
          name: 'description',
          content: '',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Financial future looking bright"
      intro="A husband and wife struggling to pay their debts feared they would eventually have to sell their home."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            The Ryhaven Trust has ensured that they not only keep their house,
            but also reroofed and redecorated it.
          </Paragraph>
          <Paragraph>
            When the trust first spoke to the couple, they were weighed down by
            massive debt. The cumulative effect of high interest rates and
            service charges to multiple finance companies had seen their loans
            double over a ten-year period.
          </Paragraph>
          <Paragraph>
            Their monthly repayments were so high that in some instances, they
            were unable to pay them. The finance companies were only too happy
            to add further debt onto their account. The situation became
            untenable when the couple felt it was impossible to service the
            bills.
          </Paragraph>
          <Paragraph>
            Lack of funds resulted in a lack of home maintenance. The exterior
            of the house had not been painted for 30 years and the roof was
            badly rusted.
          </Paragraph>
          <Paragraph>
            Our trust&rsquo;s budget advisor worked with the three finance
            companies to arrange a manageable solution for the couple, and once
            the house was secure, the Ryhaven team, along with its homeowners
            could get to work and refurbish the house.
          </Paragraph>
          <Paragraph>
            It was a big effort to repair long term damage to the windows and
            stucco walls. <a href="https://www.dulux.co.nz">Dulux</a> kindly
            supplied all the paint used for this project.
          </Paragraph>
          <Paragraph>
            The owners got busy doing some of the painting work themselves.
            Thank you to{' '}
            <a href="https://www.geeves.co.nz">Geeves Scaffolding</a> for
            providing resources.
          </Paragraph>
          <Paragraph>
            The rusted roof was replaced with a new one, due to the generous
            support of{' '}
            <a href="https://www.metalcraftgroup.co.nz">Metalcraft Roofing</a>.{' '}
            <a href="https://www.swpcommercial.co.nz">SWP Commercial</a> also
            assisted with the installation of new internal gutters.{' '}
            <a href="https://www.carters.co.nz">Carters</a> supplied materials
            for the framing.
          </Paragraph>
          <Paragraph>
            The project has taken six months to complete and like all Ryhaven
            jobs, has been a real team effort.
          </Paragraph>
          <Paragraph>
            Now the house is watertight, the owners are continuing with interior
            work. They are grateful for the trust&rsquo;s support and looking
            ahead to a brighter future.
          </Paragraph>
          <img className={styles.image} src={image5} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
          <img className={styles.image} src={image4} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
